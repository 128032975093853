<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        <v-card v-if="!loading">
          <v-card-title primary-title>
            Documents
          </v-card-title>
          <v-card-text>
            <v-select
              v-model="selectedUpload"
              :items="uploads"
              item-text="originalFilename"
              item-value="id"
              return-object
              clearable
            ></v-select>
            <div v-if="selectedUpload">
              <v-card v-if="selectedUpload.extension == 'pdf'">
                <v-card-title primary-title>
                  {{ selectedUpload.originalFilename }}
                </v-card-title>
                <v-card-text>
                  <div v-if="numPages > 1">
                    <v-btn @click="previousPage" :disabled="currentPage === 1" class="ma-2" icon color="blue lighten-2">
                      <v-icon>mdi-chevron-left</v-icon></v-btn
                    >
                    <v-btn
                      @click="nextPage"
                      :disabled="currentPage === numPages"
                      class="ma-2"
                      icon
                      color="blue lighten-2"
                      ><v-icon>mdi-chevron-right</v-icon></v-btn
                    >
                    page {{ currentPage }} of {{ numPages }}
                  </div>
                  <pdf :src="pdfSource" :page="currentPage"></pdf>
                </v-card-text>
              </v-card>
              <v-card v-else-if="isImage(selectedUpload.extension)">
                <v-card-title primary-title>
                  {{ selectedUpload.originalFilename }}
                </v-card-title>
                <v-card-text>
                  <img :src="selectedUpload.href" style="max-width: 100%;" />
                </v-card-text>
              </v-card>
              <v-card v-else>
                <v-card-title primary-title>
                  No preview available.
                </v-card-title>
                <v-card-text>
                  <a :href="selectedUpload.href" target="_blank">{{ selectedUpload.originalFilename }}</a> ({{
                    selectedUpload.contentType
                  }})
                </v-card-text>
              </v-card>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card>
          <v-card-title primary-title>
            Claim details
          </v-card-title>
          <v-card-text>
            <v-expansion-panels accordion focusable>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Overview
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-card class="cardMaxHeight">
                    <v-card-text>
                      <ClaimOverviewTab :byPage="true" :claim="claim" />
                    </v-card-text>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Form
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-card class="cardMaxHeight">
                    <v-card-text>
                      <ClaimFormTab :claim="claim" :claimTypeId="claimTypeId" :valid="valid" @onSubmit="onSubmit" />
                    </v-card-text>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Products
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-card class="cardMaxHeight">
                    <v-card-text>
                      <ClaimProductTable
                        :claim="claim"
                        :key="claimProductTableKey"
                        @errors="onClaimProductError"
                        :allowChangeAwardVehicle="
                          claimStage &&
                            claimStage.claimStageType &&
                            claimStage.claimStageType.name == 'AUDITING' &&
                            !claim.closed
                        "
                        :allowCRUDOnClaimProduct="true"
                        @change="onClaimProductChange"
                      >
                      </ClaimProductTable>
                    </v-card-text>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import ClaimProductTable from "../../../gapp-components/components/tables/ClaimProductTable.vue";
import pdf from "vue-pdf";
import ClaimOverviewTab from "./ClaimOverviewTab";
import ClaimFormTab from "./ClaimFormTab";

export default {
  components: {
    pdf,
    ClaimOverviewTab,
    ClaimFormTab,
    ClaimProductTable
  },
  name: "ClaimAuditTab",
  data: () => ({
    selectedUpload: {},
    numPages: 0,
    currentPage: 1,
    claimStage: {},
    claimProducts: {},
    claimProductTableKey: 0,
    isEditing: false
  }),
  props: {
    claimTypeId: Number,
    claim: Object,
    uploads: Array,
    loading: Boolean,
    valid: Boolean
  },
  methods: {
    async fetchData() {
      let claimStage = await this.$api.getRelatedObject("claimStage", this.claim);
      this.claimStage = claimStage.data;
      this.fetchClaimProducts();
    },
    fetchClaimProducts() {
      return this.$api.get("/api/claimProducts/byClaimId/" + this.claim.id + "?size=500&page=0").then(({ data }) => {
        this.claimProducts.existing = data.content;
        this.forceRenderClaimProductTable();
      });
    },
    forceRenderClaimProductTable() {
      this.claimProductTableKey += 1;
    },
    onSubmit(claimFieldValues) {
      this.$emit("onSubmit", claimFieldValues);
    },
    isImage(extension) {
      return ["jpg", "png", "jpeg"].includes(extension.toLowerCase());
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.numPages) {
        this.currentPage++;
      }
    },
    onClaimProductError(error) {
      this.$emit("onClaimProductError", error);
    },
    onEdit() {
      this.isEditing = !this.isEditing;
      if (!this.isEditing) {
        this.fetchData();
      }
    },
    onClaimProductChange() {
      this.fetchClaimProducts();
      this.$emit("onClaimProductChange");
    }
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    isDevelopment() {
      return process.env.VUE_APP_ENV_NAME == "Development";
    },
    pdfSource() {
      return this.isDevelopment ? this.selectedUpload.href : this.selectedUpload.filename;
    }
  },
  watch: {
    uploads: {
      immediate: true,
      deep: true,
      handler(v) {
        this.selectedUpload = v[0];
      }
    },
    claim: {
      immediate: true,
      deep: true,
      handler() {
        this.fetchData();
      }
    },
    selectedUpload: {
      immediate: true,
      deep: true,
      handler(v) {
        if (v && v.extension == "pdf") {
          let loadingTask = pdf.createLoadingTask(v.href);
          loadingTask.promise.then(pdf => {
            this.numPages = pdf.numPages;
            this.currentPage = 1;
          });
        }
      }
    }
  }
};
</script>

<style>
.cardMaxHeight {
  height: 80vh;
  overflow-y: scroll;
  margin-top: 4px;
}
</style>
