<template>
  <v-combobox
    :value="selectedItem"
    @input="onInput"
    v-bind="$attrs"
    v-on="$listeners"
    :loading="loading"
    :items="items"
    :search-input.sync="search"
    hide-no-data
    item-value="id"
    item-text="name"
    clearable
    return-object
    no-filter
    ref="combobox"
    v-if="reload"
  >
    <template v-slot:selection="{ item }">
      <slot name="selectedItemDescription" :item="item">
        {{ (showKey ? item.promotionTypeKey + " - " : "") + item.name }}</slot
      >
    </template>

    <template v-slot:item="{ item }">
      <slot name="itemDescription" :item="item">
        {{ (showKey ? item.promotionTypeKey + " - " : "") + item.name }}
      </slot>
    </template>

    <template v-slot:append-item>
      <v-list-item disabled v-if="showBottomMessage">
        <v-list-item-content>
          <v-list-item-title>
            Type to filter more records...
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item></template
    >
  </v-combobox>
</template>

<script>
import ApiService from "../../services/api.service";

export default {
  name: "PromotionTypeField",
  props: {
    value: [Object, String],
    typeKey: String,
    showKey: {
      type: Boolean,
      default: false,
      required: false
    },
    status: { type: String, required: false }
  },
  data: () => ({
    showBottomMessage: true,
    selectedItem: {},
    loading: false,
    search: null,
    items: [],
    rules: [
      v => {
        if (!!v && !v.id) {
          return "Invalid selection";
        } else {
          return true;
        }
      }
    ],
    reload: true
  }),
  methods: {
    onInput(obj) {
      if (obj && obj.id) {
        this.$emit("input", obj);
      }
    },
    clear() {
      this.reload = false;
      this.$nextTick(() => {
        this.reload = true;
      });
    }
  },
  watch: {
    value(val) {
      this.selectedItem = val;
    },
    search(val) {
      if (this.loading) {
        return;
      }
      this.loading = true;
      let filters = {};
      filters.keyword = val;
      filters.isEffective = this.status;

      ApiService.post("/api/promotionTypes/search?size=10&sort=name", filters).then(({ data }) => {
        let fullItems = data.content;
        if (fullItems.length > 9) {
          this.items = fullItems.slice(0, 9);
          this.showBottomMessage = true;
        } else {
          this.items = fullItems;
          this.showBottomMessage = false;
        }
        this.loading = false;
      });
    }
  },
  mounted() {
    this.selectedItem = this.value;
    this.search = "";
  }
};
</script>
