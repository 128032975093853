<template>
  <v-timeline v-if="valid">
    <template v-for="activity in items">
      <v-timeline-item class="mb-4" color="grey" icon-color="grey lighten-2" small :key="activity.id" right>
        <template v-slot:opposite>
          <span>
            {{ activity.createdDate | formatDateClient("MM/DD/YYYY hh:mm a z", selectedClient) }}<br />
            ({{ activity.createdDate | formatDateFromNow }})
            <span v-if="activity.user"> <br />by {{ activity.user.fullName }}</span>
          </span>
        </template>
        <v-card class="elevation-2">
          <v-card-text>
            {{ activity.message }}
          </v-card-text>
        </v-card>
      </v-timeline-item>
    </template>
    <v-timeline-item class="mb-4" hide-dot v-if="items.length > 0">
      <v-btn v-if="!isLastPage" color="accent" elevation="2" rounded text @click="showMore">Show more</v-btn>
    </v-timeline-item>
  </v-timeline>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "../../services/api.service";

export default {
  name: "ActivityTimeline",
  props: {
    type: String,
    id: Number
  },
  data: () => ({
    validType: false,
    validId: false,
    dialog: false,
    loading: false,
    itemsNumber: 10,
    isLastPage: false,
    pageNumber: 0,
    items: []
  }),

  computed: {
    ...mapGetters(["selectedParticipant", "selectedClient"]),
    valid() {
      return this.validType && this.validId;
    }
  },

  mounted() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      if (!this.valid) {
        return;
      }
      this.loading = true;

      let filters = {};
      if (this.search && this.search.length > 0) {
        filters.keyword = this.search;
      }
      if (this.id) {
        filters[this.type] = { id: this.id };
      }

      return ApiService.post(
        `/api/activities/search/${this.type}s?size=${this.itemsNumber}&page=${this.pageNumber}&sort=createdDate,DESC`,
        filters
      )
        .then(({ data }) => {
          this.isLastPage = data.totalPages == this.pageNumber + 1;
          this.loading = false;
          this.items = this.items.concat(data.content);
          return Promise.resolve(data);
        })
        .catch(e => {
          this.loading = false;
          return Promise.reject(e);
        });
    },
    showMore() {
      this.pageNumber += 1;
      this.fetchData();
    }
  },
  watch: {
    type: {
      immediate: true,
      handler(v) {
        switch (v) {
          case "approval":
          case "claim":
          case "promotion":
          case "sale":
          case "storeOrder":
            this.validType = true;
            break;
          default:
            this.validType = false;
            console.log("No activity type found");
            break;
        }
      }
    },
    id: {
      immediate: true,
      handler(v) {
        if (!v || isNaN(v)) {
          this.validId = false;
        }
        this.validId = true;
      }
    }
  }
};
</script>
