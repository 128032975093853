<template>
  <div>
    <v-select
      v-model="selectedRejectReason"
      :label="label"
      :items="dropdownItems"
      :rules="rules.rejectReason"
      variant="outlined"
      v-bind="$attrs"
      v-on="$listeners"
    ></v-select>
    <v-text-field
      label="Other Reason for Rejection"
      v-if="showOtherField"
      v-model="otherRejectReason"
      :rules="rules.otherRejectReason"
      variant="outlined"
      v-bind="$attrs"
      v-on="$listeners"
    ></v-text-field>
  </div>
</template>

<script>
import ValidationService from "@/gapp-components/services/validation.service.js";

export default {
  name: "RejectionReasonField",
  props: {
    label: {
      type: String,
      default: "Select Reason for Rejection"
    },
    allowOther: {
      type: Boolean,
      default: false
    },
    selectedRejectReasonValue: {
      type: String,
      default: null
    },
    rejectReason: {
      type: String,
      default: ""
    },
    rejectionType: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      selectedRejectReason: this.selectedRejectReasonValue,
      otherRejectReason: this.rejectReason,
      rules: {
        rejectReason: ValidationService.generateDropdownValidationRules("Select Reason for Rejection"),
        otherRejectReason: ValidationService.generateTextValidationRules("Other Reason for Rejection", 200)
      },
      arrayRejectClaimReasons: []
    };
  },
  methods: {
    fetchRejectionData() {
      let filters = {};
      filters.rejectionType = {
        name: this.rejectionType
      };
      this.$api.post(`/api/rejectionReasons/search`, filters).then(({ data }) => {
        const reasons = data?.content?.map(item => item.reason);
        this.arrayRejectClaimReasons = reasons;
      });
    },
    handleRejectionChange() {
      this.$emit("update:selectedRejectReason", {
        selectedRejectReasonValue: this.selectedRejectReason,
        rejectReason: this.otherRejectReason
      });
    }
  },
  computed: {
    dropdownItems() {
      return this.allowOther ? [...this.arrayRejectClaimReasons, "Other"] : this.arrayRejectClaimReasons;
    },
    showOtherField() {
      return this.allowOther && this.selectedRejectReason === "Other";
    }
  },
  watch: {
    selectedRejectReason(newVal) {
      if (newVal !== "Other") {
        this.otherRejectReason = "";
      }
      this.handleRejectionChange();
    },
    otherRejectReason() {
      this.handleRejectionChange();
    }
  },
  mounted() {
    this.fetchRejectionData();
  }
};
</script>

<style scoped>
/* Add styles if needed */
</style>
