<template>
  <v-container>
    <v-row justify="end" v-if="allowCRUDEditing">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn fab small class="mr-2" v-on="on" @click="toggleEditing" :disabled="isSaving">
            <v-icon v-if="isEditing">mdi-close</v-icon>
            <v-icon v-else>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>{{ isEditing ? "Cancel editing" : "Edit Payouts" }}</span>
      </v-tooltip>
      <v-tooltip bottom v-if="isEditing">
        <template v-slot:activator="{ on }">
          <v-btn fab small class="mr-2" v-on="on" @click="onSubmit" :disabled="isSaving">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </template>
        <span>Save changes</span>
      </v-tooltip>
    </v-row>

    <v-data-table style="width: 100%" :headers="headers" :items="items" :loading="loading" class="elevation-1">
      <template v-slot:item.payoutAmount="{ item }">
        <v-text-field
          v-if="isEditing"
          v-model="item.payoutAmount"
          type="number"
          label="Payout Amount"
          single-line
          dense
          @input="item.dirty = true"
        ></v-text-field>
        <span v-else>{{ item.payoutAmount | toCurrency }}</span>
      </template>

      <template v-slot:item.payoutType="{ item }">
        <span> {{ item.payoutType.description }}</span>
      </template>

      <template v-slot:item.currency="{ item }">
        <span> {{ item.currency.name }}</span>
      </template>

      <template v-slot:item.awardVehicleDetermination="{ item }">
        <span>{{ item.awardVehicleDetermination.description }}</span>
      </template>

      <template v-slot:item.awardVehicleName="{ item }">
        <AwardVehicleField v-model="item.awardVehicle" v-if="isEditing" @input="item.dirty = 'true'">
        </AwardVehicleField>
        <span v-else>{{ item.awardVehicleName ? item.awardVehicleName : "No Award  Vehicle Selected" }}</span>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon v-if="isEditing" @click="item.markedForDeletion = true">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "../../services/api.service";
import AwardVehicleField from "../fields/AwardVehicleField";

export default {
  name: "ClaimPayoutsComponent",
  components: {
    AwardVehicleField
  },
  props: {
    items: Array,
    allowCRUDEditing: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isEditing: false,
      isSaving: false,
      loading: false,
      headers: [
        { text: "Payout Amount", value: "payoutAmount" },
        { text: "Payout Type", value: "payoutType" },
        { text: "Award Vehicle", value: "awardVehicleName" },
        { text: "Currency", value: "currency" },
        { text: "Award Vehicle Determination Type", value: "awardVehicleDetermination" },
        {
          value: "actions",
          text: "",
          align: "center",
          sortable: false,
          show: false
        }
      ]
    };
  },

  methods: {
    toggleEditing() {
      this.isEditing = !this.isEditing;
      this.claim.claimPayouts.forEach(cp => {
        cp.awardVehicleName = cp.awardVehicle.name;
      });
    },
    onSubmit() {
      this.isSaving = true;

      let payoutsToUpdate = this.claim.claimPayouts.filter(payout => payout.dirty);
      let updatePromises = payoutsToUpdate.map(payout =>
        ApiService.patch(`/api/payouts/${payout.id}`, {
          payoutAmount: payout.payoutAmount,
          awardVehicle: payout.awardVehicle ? ApiService.getSelfUrl("awardVehicles", payout.awardVehicle) : null
        })
      );

      let payoutsToDelete = this.claim.claimPayouts.filter(payout => payout.markedForDeletion);
      let deletePromises = payoutsToDelete.map(payout => ApiService.delete(`/api/claimPayouts/${payout.id}`));

      Promise.all([...updatePromises, ...deletePromises])
        .then(() => {
          this.isSaving = false;
          this.isEditing = false;
          this.claim.claimPayouts.forEach(cp => (cp.awardVehicleName = cp.awardVehicle.name));
          this.$emit("update", true);
        })
        .catch(error => {
          console.error("Failed to update payouts:", error);
          this.isSaving = false;
        });
    }
  },
  computed: {
    ...mapGetters(["selectedProgram", "selectedClient"])
  }
};
</script>

<style scoped></style>
