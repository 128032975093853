<template>
  <v-container>
    <v-row justify="end" v-if="allowCRUDOnClaimProduct">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn fab small class="mr-2" v-on="on" @click="onEdit" :disabled="disabled">
            <v-icon v-if="isEditing">mdi-close</v-icon>
            <v-icon v-else>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span v-if="isEditing">{{ $i18n.translate("Close") }}</span>
        <span v-else
          >{{ $i18n.translate("Edit") }} {{ $i18n.translate("Claim") }} {{ $i18n.translate("Product") }}</span
        >
      </v-tooltip>
      <v-tooltip bottom v-if="isEditing">
        <template v-slot:activator="{ on }">
          <v-btn fab small class="mr-2" v-on="on" @click="addClaimProductDialog = true">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>{{ $i18n.translate("Add") }} {{ $i18n.translate("Claim") }} {{ $i18n.translate("Product") }}</span>
      </v-tooltip>
      <v-tooltip bottom v-if="isEditing">
        <template v-slot:activator="{ on }">
          <v-btn fab small class="mr-2" v-on="on" @click="onSubmit">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </template>
        <span>{{ $i18n.translate("Save") }}</span>
      </v-tooltip>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="claimProducts.existing"
      :value="claimProducts.existing"
      hide-default-footer
      disable-pagination
    >
      <template v-slot:item.promotionProductKey="{ item }">
        <span v-if="!isEditing">{{ item.promotionProductKey }}</span>
        <v-autocomplete
          v-if="isEditing && allowCRUDOnClaimProduct"
          :items="promotionProducts"
          v-model="item.promotionProduct"
          label="Select a Product"
          item-value="id"
          :item-text="
            item => {
              return item && item.product ? item.product.productKey : '';
            }
          "
          return-object
          @change="item.dirty = true"
          style="width:175px"
        ></v-autocomplete>
      </template>

      <template v-slot:item.quantity="{ item }">
        <span v-if="!isEditing">{{ item.quantity }}</span>
        <v-text-field
          v-model="item.quantity"
          v-if="isEditing && allowCRUDOnClaimProduct"
          @change="item.dirty = true"
          @input.stop=""
          :disabled="item.promotionProduct.enableSerialNumbers"
        >
        </v-text-field>
      </template>

      <template v-slot:item.customFields="{ item }">
        <v-icon
          @click="showCustomFields(item)"
          v-if="item.promotionProduct.enableCustomField1 || item.promotionProduct.enableCustomField2"
          >mdi-eye</v-icon
        >
      </template>

      <template v-slot:item.serialNumber="{ item }">
        <span v-if="!isEditing">{{ item.serialNumber }}</span>

        <v-text-field
          v-model="item.serialNumber"
          v-if="isEditing && allowCRUDOnClaimProduct"
          @input="item.dirty = true"
          @keydown="keyHandler($event)"
          :disabled="!item.promotionProduct.enableSerialNumbers"
        >
        </v-text-field>

        <v-icon v-if="item.serialNumberLookup && item.serialNumberLookup.id" color="green">mdi-check</v-icon>
        <v-tooltip
          bottom
          v-if="item.serialNumberLookup && item.serialNumberLookup.id && item.serialNumberLookup.participantProvided"
        >
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" color="primary">mdi-account</v-icon>
          </template>
          Provided by the Participant
        </v-tooltip>

        <span v-if="!(item.serialNumberLookup && item.serialNumberLookup.id)">
          <v-icon color="red">mdi-close</v-icon>
        </span>
        <v-tooltip bottom v-if="item && (item.showCreateIcon || item.dirty)">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" v-if="!item.savingSerialNumber" @click="saveSerialNumber(item)">
              mdi-content-save
            </v-icon>
            <v-progress-circular size="20" v-else indeterminate color="primary"></v-progress-circular>
          </template>
          Add {{ $i18n.translate("Serial Number") }} to database
        </v-tooltip>
        <br />
        <span style="font-style: italic; color:red">{{ item.serialNumberLookupMessage }}</span>
      </template>

      <template v-slot:item.priceAmount="{ item }">
        {{ item.priceAmount | toNumber(2) }}
        {{ item.priceAmountCurrency ? item.priceAmountCurrency.name : "" }}
      </template>

      <template v-slot:item.payouts="{ item }">
        <ol v-if="item.payouts && item.payouts.length > 0">
          <li v-for="(claimProductPayout, i) in item.payouts" :key="i" class="text-no-wrap">
            <span :key="'payout-' + claimProductPayout.id">
              <span v-if="claimProductPayout.payoutType && claimProductPayout.payoutType.name == 'PRODUCT'">
                {{
                  (claimProductPayout.payoutProductQuantity == 1
                    ? " 1 unit of "
                    : claimProductPayout.payoutProductQuantity + " units of ") +
                    claimProductPayout.payoutStoreCatalogProduct.storeProduct.name
                }}
                ({{ claimProductPayout.payoutStoreCatalogProduct.storeProduct.productNumber }})
              </span>
              <span v-if="claimProductPayout.payoutType && claimProductPayout.payoutType.name == 'COUPON_CODE'">
                {{
                  claimProductPayout.payoutProductQuantity == 1
                    ? " 1 coupon code"
                    : claimProductPayout.payoutProductQuantity + " coupon codes"
                }}
              </span>
              <span v-else>
                {{ claimProductPayout.calculatedPayoutAmount | toNumber(2, selectedProgram) }}
                {{
                  claimProductPayout.calculatedPayoutAmountCurrency
                    ? claimProductPayout.calculatedPayoutAmountCurrency
                    : ""
                }}
              </span>
            </span>
          </li>
        </ol>
      </template>
      <template v-slot:item.payoutType="{ item }">
        <ol v-if="item.payouts && item.payouts.length > 0">
          <li v-for="(claimProductPayout, i) in item.payouts" :key="i" class="text-no-wrap">
            <span :key="'payout-type-' + claimProductPayout.id">
              {{ claimProductPayout.payoutType.description }}
            </span>
          </li>
        </ol>
      </template>
      <template v-slot:item.awardVehicleName="{ item }">
        <ol v-if="item.payouts && item.payouts.length > 0">
          <li v-for="(claimProductPayout, i) in item.payouts" :key="i" class="text-no-wrap">
            <span :key="'av-determination-' + claimProductPayout.id">
              {{ claimProductPayout.awardVehicleName }}
              <span v-if="claimProductPayout.awardVehicleDetermination" style="font-style: italic">
                <br />
                {{ claimProductPayout.awardVehicleDetermination.description }}
              </span>
            </span>
            <br />
            <v-btn
              v-if="isEditing && allowChangeAwardVehicle"
              class="ma-2"
              color="info"
              @click="onChangeAwardVehicleDialog(item)"
            >
              Change Award Vehicle
              <v-icon right dark>
                mdi-pencil
              </v-icon>
            </v-btn>
          </li>
        </ol>

        <span v-else>No Payout</span>
      </template>
      <template v-slot:item.purchaseDate="{ item }">
        <span v-if="!isEditing">{{ $util.formatDateClient(item.purchaseDate, "MM/DD/YYYY", selectedClient) }}</span>
        <DateTimePickerField
          style="width:250px"
          v-model="item.purchaseDate"
          v-if="isEditing && allowCRUDOnClaimProduct"
          @input="item.dirty = true"
          @keydown="keyHandler($event)"
          label="Purchase Date"
          defaultTime="00:00"
        ></DateTimePickerField>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon v-if="isEditing && allowCRUDOnClaimProduct" @click="onDeleteProductAttempt(item)">
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:body.append>
        <tr>
          <th class="text-start">Total Product Quantity</th>
          <th class="text-center">{{ totalQuantity }}</th>
          <th colspan="2" class="text-start">Total Payout of Claim</th>
          <th class="text-center">
            {{ claim.calculatedCalculatedTotalClaimAmount | toNumber(2, selectedProgram) }}
            {{
              claim.calculatedCalculatedTotalClaimAmountCurrency
                ? claim.calculatedCalculatedTotalClaimAmountCurrency
                : ""
            }}
          </th>
        </tr>
      </template>
    </v-data-table>

    <v-dialog v-model="changeAwardVehicleDialog" v-if="changeAwardVehicleDialog" max-width="500">
      <v-card>
        <v-card-title>Change Award Vehicle</v-card-title>
        <v-card-text>
          <v-alert v-if="itemHasReferenceNumberWarning" type="warning"
            >The current claim product has reference numbers associated with it.</v-alert
          >
          <AwardVehicleField
            label="Award Vehicle"
            v-model="changeAwardVehicleClaimProductAwardVehicle"
            :available="changeAwardVehicleAvailableAwardVehicles"
            :clearable="false"
            :disabled="!allowChangeAwardVehicle"
          ></AwardVehicleField>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :loading="loading" @click="changeAwardVehicleDialog = false">{{ $i18n.translate("Cancel") }}</v-btn>
          <v-btn class="primary" :loading="loading" @click="onChangeAwardVehicle">{{ $i18n.translate("Save") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteClaimProductDialog" v-if="deleteClaimProductDialog" max-width="500">
      <v-card>
        <v-card-title>
          Delete this claim product?
        </v-card-title>
        <v-card-text>
          <v-alert type="warning">The current claim product has reference numbers associated with it.</v-alert>
          Are you sure you want to delete it?
        </v-card-text>

        <v-card-actions
          ><v-spacer></v-spacer
          ><v-btn
            class="primary"
            @click="
              onDeleteProduct(claimProductToDelete);
              deleteClaimProductDialog = false;
            "
            >Yes</v-btn
          ><v-btn class="primary" @click="deleteClaimProductDialog = false">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showCustomFieldsDialog" max-width="500">
      <v-card>
        <v-card-title>
          Custom Fields
        </v-card-title>
        <v-card-text>
          <v-row>
            <CustomField
              v-if="selectedItem"
              v-model="selectedItem.customFieldValue1"
              :customField="selectedProgram.claimProductCustomFields[0]"
            />
          </v-row>
          <v-row>
            <CustomField
              v-if="selectedItem"
              v-model="selectedItem.customFieldValue2"
              :customField="selectedProgram.claimProductCustomFields[1]"
            />
          </v-row>
          <v-row>
            <CustomField
              v-if="selectedItem"
              v-model="selectedItem.customFieldValue3"
              :customField="selectedProgram.claimProductCustomFields[2]"
            />
          </v-row>
          <v-row>
            <CustomField
              v-if="selectedItem"
              v-model="selectedItem.customFieldValue4"
              :customField="selectedProgram.claimProductCustomFields[3]"
            />
          </v-row>
          <v-row>
            <CustomField
              v-if="selectedItem"
              v-model="selectedItem.customFieldValue5"
              :customField="selectedProgram.claimProductCustomFields[4]"
            />
          </v-row>
          <v-row>
            <CustomField
              v-if="selectedItem"
              v-model="selectedItem.customFieldValue6"
              :customField="selectedProgram.claimProductCustomFields[5]"
            />
          </v-row>
          <v-row>
            <CustomField
              v-if="selectedItem"
              v-model="selectedItem.customFieldValue7"
              :customField="selectedProgram.claimProductCustomFields[6]"
            />
          </v-row>
          <v-row>
            <CustomField
              v-if="selectedItem"
              v-model="selectedItem.customFieldValue8"
              :customField="selectedProgram.claimProductCustomFields[7]"
            />
          </v-row>
          <v-row>
            <CustomField
              v-if="selectedItem"
              v-model="selectedItem.customFieldValue9"
              :customField="selectedProgram.claimProductCustomFields[8]"
            />
          </v-row>
          <v-row>
            <CustomField
              v-if="selectedItem"
              v-model="selectedItem.customFieldValue10"
              :customField="selectedProgram.claimProductCustomFields[9]"
            />
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer><v-btn @click="showCustomFieldsDialog = false">Close</v-btn></v-spacer></v-card-actions
        >
      </v-card>
    </v-dialog>
    <v-dialog v-model="addClaimProductDialog" max-width="500">
      <v-card>
        <v-card-title>
          New claim product
        </v-card-title>
        <v-card-text>
          <v-form
            :value="addClaimProductFormValid"
            @submit.prevent="onAddClaimProduct()"
            v-model="addClaimProductFormValid"
          >
            <v-row>
              <v-autocomplete
                :items="promotionProducts"
                v-model="selectedPromotionProduct"
                label="Select a Product"
                item-value="id"
                :item-text="
                  item => {
                    return item && item.product ? item.product.productKey : '';
                  }
                "
                return-object
              ></v-autocomplete>
            </v-row>
            <v-row>
              <v-text-field
                :label="$i18n.translate('Serial Number')"
                v-model="addClaimProduct.serialNumber"
                v-if="selectedPromotionProduct && selectedPromotionProduct.enableSerialNumbers"
                @blur="formatSerialNumber(addClaimProduct)"
                :rules="
                  selectedPromotionProduct && selectedPromotionProduct.enableSerialNumbers
                    ? addClaimProductRules.serialNumber
                    : []
                "
              ></v-text-field>
              <v-text-field
                label="Quantity"
                type="number"
                min="1"
                max="9999999"
                v-model="addClaimProduct.quantity"
                class="required"
                v-else
                :rules="addClaimProductRules.quantity"
              ></v-text-field>
            </v-row>
            <v-row>
              <DateTimePickerField
                v-model="addClaimProduct.purchaseDate"
                label="Purchase Date"
                defaultTime="00:00"
              ></DateTimePickerField>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions
          ><v-spacer></v-spacer
          ><v-btn class="primary" :disabled="!addClaimProductFormValid" @click="onAddClaimProduct()">Add</v-btn
          ><v-btn class="primary" @click="addClaimProductDialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "../../services/api.service";
import AwardVehicleField from "../fields/AwardVehicleField";
import CustomField from "../fields/CustomField";
import DateTimePickerField from "../fields/DateTimePickerField";
import moment from "moment-timezone";

export default {
  name: "ClaimProductTable",
  props: {
    disabled: Boolean,
    allowCRUDOnClaimProduct: {
      type: Boolean,
      default: false
    },
    allowChangeAwardVehicle: {
      type: Boolean,
      default: false
    },
    claim: Object
  },
  components: {
    AwardVehicleField,
    CustomField,
    DateTimePickerField
  },
  data: () => ({
    loading: false,
    changeSerialNumberClaimProduct: undefined,
    changeSerialNumberClaimProductSerialNumber: undefined,
    changeAwardVehicleDialog: false,
    changeAwardVehicleAvailableAwardVehicles: undefined,
    changeAwardVehicleClaimProductAwardVehicle: undefined,
    addClaimProductRules: {
      serialNumber: [v => !!v || "Serial Number is required"],
      quantity: [v => !!v || "Quantity is required"]
    },
    addClaimProductFormValid: true,
    addClaimProductDialog: false,
    addClaimProduct: {
      quantity: 1,
      serialNumber: "",
      purchaseDate: null
    },
    promotionProducts: [],
    selectedPromotionProduct: undefined,
    deleteClaimProductDialog: false,
    claimProductToDelete: undefined,
    isEditing: false,
    showCreateIcon: false,
    editedIndex: -1,
    errors: {},
    selectedItem: null,
    showCustomFieldsDialog: false,
    totalClaimProducts: 0,
    claimProducts: {
      existing: [],
      deleted: []
    },
    headers: [
      {
        value: "promotionProductKey",
        text: "Product",
        sortable: true
      },
      {
        value: "promotionProductName",
        text: "Name",
        sortable: false
      },
      {
        value: "quantity",
        text: "QTY",
        align: "center",
        sortable: true
      },

      {
        value: "serialNumber",
        text: "Serial Number",
        sortable: true
      },
      {
        value: "sku",
        text: "SKU",
        sortable: false
      },
      {
        value: "priceAmount",
        text: "Price Amount",
        sortable: false
      },
      {
        value: "payouts",
        text: "Payouts",
        sortable: false
      },
      {
        value: "payoutType",
        text: "Type",
        sortable: false
      },
      {
        value: "awardVehicleName",
        text: "Award Vehicle",
        sortable: false
      },
      {
        value: "purchaseDate",
        text: "Purchase Date",
        sortable: false
      },
      {
        value: "customFields",
        text: "Custom Fields",
        sortable: false
      },
      {
        value: "actions",
        text: "",
        align: "center",
        sortable: false,
        show: false
      }
    ]
  }),
  created() {
    this.fetchClaimProducts();
    this.getPromotionProducts();
  },
  methods: {
    showCustomFields(item) {
      this.selectedItem = item;
      this.showCustomFieldsDialog = true;
    },
    saveSerialNumber(item) {
      this.$set(item, "savingSerialNumber", true);
      this.$nextTick(() => {
        this.$api
          .post("/api/productSerialNumbers", {
            product: ApiService.getSelfUrl("products", item.promotionProduct.product),
            productSerialNumberKey: item.serialNumber,
            participantProvided: true
          })
          .then(() => {
            this.$set(item, "savingSerialNumber", false);
            this.fetchSerialNumber(item);
          })
          .catch(errors => {
            this.$set(item, "savingSerialNumber", false);
            this.errors = ApiService.getErrorsFromResponse(errors);
          });
      });
    },
    onInput() {
      this.$emit("input", this.claimProducts);
    },
    fetchClaimProducts() {
      this.loading = true;
      this.$api
        .get("/api/claimProducts/byClaimId/" + this.claim.id + "?size=1000")
        .then(({ data }) => {
          this.claimProducts.existing = data.content;
          if (this.claimProducts && this.claimProducts.existing) {
            this.claimProducts.existing.map(i => {
              i.promotionProductKey = i.promotionProduct.product.productKey;
              i.promotionProductName = i.promotionProduct.product.name;
              i.promotionProduct1 = i.promotionProduct;
              i.dirty = false;
            });

            this.claimProducts.existing.reduce(
              (p, claimProduct) => this.fetchSerialNumber(claimProduct),
              Promise.resolve()
            );
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchSerialNumber(claimProduct) {
      if (claimProduct.serialNumberLookup) {
        delete claimProduct.serialNumberLookup;
      }
      if (claimProduct.serialNumberLookupMessage) {
        delete claimProduct.serialNumberLookupMessage;
      }
      claimProduct.showCreateIcon = false;

      this.$set(claimProduct, "serialNumberLookup", {});

      if (
        !claimProduct.promotionProduct ||
        !claimProduct.serialNumber ||
        claimProduct.serialNumber.trim().length == 0
      ) {
        return Promise.resolve();
      }
      return ApiService.post("/api/productSerialNumbers/search?size=1", {
        product: { id: claimProduct.promotionProduct.product.id },
        productSerialNumberKey: claimProduct.serialNumber
      }).then(({ data }) => {
        if (data && data.content && data.content.length == 1) {
          this.$set(claimProduct, "serialNumberLookup", data.content[0]);
        } else {
          ApiService.get("/api/products/search/findBySerialNumber?serialNumber=" + claimProduct.serialNumber).then(
            ({ data }) => {
              if (data && data._embedded && data._embedded.products) {
                let message = "";
                let showCreateIcon = false;
                if (data._embedded.products.length == 0) {
                  message = "This " + this.$i18n.translate("Serial Number") + " doesn't exist in our system";
                  showCreateIcon = true;
                }
                if (data._embedded.products.length > 0) {
                  message =
                    "This " +
                    this.$i18n.translate("Serial Number") +
                    " exists for product" +
                    (data._embedded.products.length > 1 ? "s" : "") +
                    " " +
                    data._embedded.products.map(p => p.productKey).join(", ") +
                    ".";
                }
                this.$set(claimProduct, "serialNumberLookupMessage", message);
                this.$set(claimProduct, "showCreateIcon", showCreateIcon);
              }
            }
          );
        }
      });
    },
    onChangeSerialNumber(item) {
      let postForm = {};
      postForm.quantity = item.quantity;
      if (item.purchaseDate) {
        postForm.purchaseDate = moment(item.purchaseDate).format("YYYY-MM-DDTHH:mm:ss.SSSZ");
      } else {
        postForm.purchaseDate = null;
      }
      postForm.serialNumber = item.serialNumber;
      postForm.promotionProduct = item.promotionProduct;
      return ApiService.post("/api/claims/" + this.claim.id + "/updateClaimProduct/" + item.id, postForm)
        .catch(error => {
          this.$emit("errors", ApiService.getErrorsFromResponse(error));
        })
        .finally(() => {
          item.dirty = false;
          this.fetchClaimProducts();
          this.$emit("change");
        });
    },
    onChangeAwardVehicleDialog(claimProduct) {
      this.editedIndex = this.claimProducts.existing.indexOf(claimProduct);
      this.changeAwardVehicleClaimProduct = Object.assign({}, claimProduct);
      this.changeAwardVehicleClaimProductAwardVehicle =
        this.changeAwardVehicleClaimProduct.payouts && this.changeAwardVehicleClaimProduct.payouts.length > 0
          ? this.changeAwardVehicleClaimProduct.payouts[0].awardVehicle
          : null;
      ApiService.get(
        "/api/claims/search/findByClaimProduct?claimProductId=" + this.changeAwardVehicleClaimProduct.id
      ).then(({ data }) => {
        ApiService.getRelatedObject("promotion", data).then(({ data }) => {
          ApiService.getRelatedObject("promotionType", data).then(({ data }) => {
            ApiService.getRelatedObject("availableAwardVehicles", data).then(({ data }) => {
              this.$set(this, "changeAwardVehicleAvailableAwardVehicles", data._embedded.awardVehicles);

              this.changeAwardVehicleDialog = true;
            });
          });
        });
      });
    },
    onChangeAwardVehicle() {
      this.loading = true;
      return ApiService.post("/api/claimProducts/" + this.changeAwardVehicleClaimProduct.id + "/updateAwardVehicle", {
        awardVehicle: this.changeAwardVehicleClaimProductAwardVehicle,
        awardVehicleDeterminationType: {
          name: "DETERMINED_BY_MANUAL",
          description: "Determined manually by auditor"
        }
      })
        .then(() => {
          this.fetchClaimProducts();
          this.$emit("change");
        })
        .catch(error => {
          this.$emit("errors", ApiService.getErrorsFromResponse(error));
        })
        .finally(() => {
          this.changeAwardVehicleDialog = false;
          this.loading = false;
        });
    },
    onDeleteProductAttempt(item) {
      this.claimProductToDelete = item;
      if (item.payouts.some(cpp => cpp.referenceNumber)) {
        this.deleteClaimProductDialog = true;
      } else {
        this.onDeleteProduct(item);
      }
    },
    onDeleteProduct(item) {
      return ApiService.delete("/api/claims/" + this.claim.id + "/deleteClaimProduct/" + item.id)
        .catch(error => {
          this.$emit("errors", ApiService.getErrorsFromResponse(error));
        })
        .finally(() => {
          this.fetchClaimProducts();
          this.$emit("change");
        });
    },
    keyHandler(e) {
      if (e.key == " " || e.key == ",") {
        e.preventDefault();
      }
    },
    onEdit() {
      this.isEditing = !this.isEditing;
      if (!this.isEditing) {
        this.$set(this, "claimProducts", this.claimProducts);
      }
    },
    onSubmit() {
      let promises = [];

      let itemsToSubmit = this.claimProducts.existing.filter(elem => elem.dirty);
      promises = itemsToSubmit.reduce((e1, e2) => {
        return e1.then(() => {
          return this.onChangeSerialNumber(e2).then(() => this.fetchSerialNumber(e2));
        });
      }, Promise.resolve());

      promises
        .then(() => {
          this.isEditing = false;
          this.$emit("input", this.claimProducts);
        }, Promise.resolve())
        .then(() => {
          if (itemsToSubmit.length > 0) {
            this.isEditing = false;
            this.$emit("input", this.claimProducts);
            this.fetchClaimProducts();
            this.$emit("change");
          } else {
            this.fetchClaimProducts();
            this.$emit("change");
          }
        });
    },
    onAddClaimProduct() {
      let postForm = {};
      postForm.quantity = this.addClaimProduct.quantity;
      postForm.serialNumber = this.addClaimProduct.serialNumber;
      postForm.promotionProduct = this.selectedPromotionProduct;
      postForm.purchaseDate = this.addClaimProduct.purchaseDate;
      return ApiService.post("/api/claims/" + this.claim.id + "/addClaimProduct", postForm)
        .catch(error => {
          this.$emit("errors", ApiService.getErrorsFromResponse(error));
        })
        .finally(() => {
          this.addClaimProductDialog = false;
          this.fetchClaimProducts();
          this.$emit("change");
        });
    },
    formatSerialNumber(item) {
      if (item.serialNumber) {
        if (item.serialNumber.startsWith("S01")) {
          item.serialNumber = item.serialNumber.substring(3);
        }
        if (item.serialNumber.length < 7) {
          item.serialNumber = item.serialNumber.padStart(7, "0");
        }
      }
    },
    getPromotionProducts() {
      ApiService.getRelatedObject("promotion", this.claim).then(({ data }) => {
        ApiService.post("/api/promotionProducts/search?size=500&page=0&nestedSort=product.productKey,ASC", {
          promotion: { id: data.id },
          isEffective: true
        }).then(({ data }) => {
          this.promotionProducts = data.content;
        });
      });
    }
  },
  computed: {
    ...mapGetters(["selectedProgram", "selectedClient"]),
    computedErrors() {
      return this.errors
        ? Object.values(this.errors).reduce((arr, cur) => {
            arr = arr.concat(cur);
            return arr;
          }, [])
        : [];
    },
    itemHasReferenceNumberWarning() {
      if (this.changeSerialNumberClaimProduct) {
        return this.changeSerialNumberClaimProduct.payouts.some(cpp => cpp.referenceNumber);
      }
      if (this.changeAwardVehicleClaimProduct) {
        return this.changeAwardVehicleClaimProduct.payouts.some(cpp => cpp.referenceNumber);
      }
      return false;
    },
    totalQuantity() {
      return this.claimProducts.existing.reduce((x, y) => {
        return x + +(y.quantity == null || y.quantity == "" ? 0 : y.quantity);
      }, 0);
    }
  }
};
</script>
