<template>
  <div class="text-center" v-if="loading">
    <v-progress-circular indeterminate color="primary"></v-progress-circular>
  </div>
  <v-container v-else>
    <v-row>
      <v-col cols="6">
        <v-card :loading="loading" height="100%">
          <v-toolbar flat>
            <v-toolbar-title>{{ $i18n.translate("Claim") }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-tooltip bottom v-if="hasReferenceNumbers">
              <template v-slot:activator="{ on: on }">
                <v-chip color="orange" class="mr-1" v-on="on">{{ $i18n.translate("Reference Numbers") }}</v-chip>
              </template>
              <span>{{ $i18n.translate("Includes Credit Memo Reference Numbers") }} </span>
            </v-tooltip>

            <v-tooltip bottom v-if="claim.approved">
              <template v-slot:activator="{ on: on }">
                <v-chip color="green" v-on="on">{{ $i18n.translate("Approved") }}</v-chip>
              </template>
              <span
                >{{ $i18n.translate("Approved") }}
                {{
                  claim.approvedUser && claim.approvedUser.fullName
                    ? " " + $i18n.translate("by") + " " + claim.approvedUser.fullName
                    : ""
                }}
                on
                {{ claim.approvedDate | formatDateClient("MM/DD/YYYY hh:mm a z", selectedClient) }}
              </span>
            </v-tooltip>
            <v-tooltip bottom v-if="claim.rejected">
              <template v-slot:activator="{ on: on }">
                <v-chip color="red" v-on="on">{{ $i18n.translate("Rejected") }}</v-chip>
              </template>
              <span
                >{{ $i18n.translate("Rejected") }}
                {{
                  claim.rejectedUser && claim.rejectedUser.fullName
                    ? " " + $i18n.translate("by") + " " + claim.rejectedUser.fullName
                    : ""
                }}
                on
                {{ claim.rejectedDate | formatDateClient("MM/DD/YYYY hh:mm a z", selectedClient) }}
              </span>
            </v-tooltip>
            <v-tooltip bottom v-if="isGappInternalUser && claim.id">
              <template v-slot:activator="{ on: on }">
                <v-btn icon v-on="on" @click="$portal.navigateClaim(claim.id)">
                  <v-icon color="primary">mdi-open-in-new</v-icon>
                </v-btn>
              </template>
              <span>{{ $i18n.translate("Go to") }} {{ $i18n.translate("Claim") }}</span>
            </v-tooltip>
          </v-toolbar>
          <v-card-text>
            <v-text-field :label="$i18n.translate('Claim Key')" v-model="claim.claimKey" disabled></v-text-field>
            <ClaimTypeField v-model="claimType" :label="$i18n.translate('Claim Type')" disabled></ClaimTypeField>
            <ClaimStageField
              v-model="claimStage"
              :claimType="claimType"
              :label="$i18n.translate('Claim Stage')"
              disabled
            ></ClaimStageField>
            <v-text-field
              :label="$i18n.translate('Claim Amount')"
              :value="
                claim.calculatedCalculatedTotalClaimAmount + ' ' + claim.calculatedCalculatedTotalClaimAmountCurrency
              "
              disabled
            ></v-text-field>
            <v-text-field
              :label="$i18n.translate('Paid Claim Amount')"
              :value="
                claim.calculatedCalculatedTotalClaimAmountPaid +
                  ' ' +
                  claim.calculatedCalculatedTotalClaimAmountPaidCurrency
              "
              disabled
            ></v-text-field>
            <v-text-field
              :label="$i18n.translate('Approved') + '?'"
              :value="
                claim.approved
                  ? 'Yes' +
                    (claim.approvedUser && claim.approvedUser.fullName ? ', by ' + claim.approvedUser.fullName : '')
                  : 'No'
              "
              disabled
            ></v-text-field>
            <v-text-field
              :label="$i18n.translate('Rejected') + '?'"
              :value="
                claim.rejected
                  ? 'Yes' +
                    (claim.rejectedUser && claim.rejectedUser.fullName ? ', by ' + claim.rejectedUser.fullName : '') +
                    ', because ' +
                    claim.rejectedReason
                  : 'No'
              "
              disabled
            ></v-text-field>
            <v-text-field
              :loading="loading"
              :label="$i18n.translate('Follow Up')"
              v-model="claim.followUp"
              append-icon="mdi-close"
              :append-outer-icon="claim.followUp ? 'mdi-content-save' : ''"
            ></v-text-field>
            <v-text-field label="Who Uploaded" :value="uploadedUserFullName" disabled></v-text-field>
            <DateTimePickerField label="Date Uploaded" v-model="claim.uploadedDate" disabled></DateTimePickerField>
            <v-text-field
              :label="$i18n.translate('Claim Browser Source')"
              v-model="claim.browserSource"
              disabled
            ></v-text-field>
            <v-text-field
              :label="$i18n.translate('Claim Browser Referer')"
              v-model="claim.browserReferer"
              disabled
            ></v-text-field>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card height="100%">
          <v-toolbar flat>
            <v-toolbar-title>{{ $i18n.translate("Promotion") }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-tooltip bottom v-if="isGappInternalUser && promotion.id">
              <template v-slot:activator="{ on: on }">
                <v-btn icon v-on="on" @click="$portal.navigatePromotion(promotion.id)">
                  <v-icon color="primary">mdi-open-in-new</v-icon>
                </v-btn>
              </template>
              <span>{{ $i18n.translate("Go to") }} {{ $i18n.translate("Promotion") }}</span>
            </v-tooltip>
          </v-toolbar>
          <v-card-text>
            <v-text-field :label="$i18n.translate('Promotion Key')" v-model="promotion.promotionKey" disabled>
            </v-text-field>
            <v-text-field
              :label="$i18n.translate('Promotion Name')"
              v-if="promotion"
              v-model="promotion.name"
              disabled
            ></v-text-field>
            <PromotionTypeField
              v-model="promotionType"
              :label="$i18n.translate('Promotion Type')"
              disabled
            ></PromotionTypeField>
            <CountryField
              v-model="promotion.country"
              :label="$i18n.translate('Promotion') + ' ' + $i18n.translate('Country')"
              disabled
              :available="promotion.country ? [promotion.country] : null"
            ></CountryField
            ><br />
            <v-chip class="primary" small v-if="promotionType.publiclyAvailable">{{
              $i18n.translate("Publicly Available")
            }}</v-chip>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-card height="100%">
          <v-toolbar flat>
            <v-toolbar-title>{{ $i18n.translate("Participant") }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-tooltip bottom v-if="isGappInternalUser && participant.id">
              <template v-slot:activator="{ on: on }">
                <v-btn icon v-on="on" @click="$portal.navigateParticipant(participant.id)">
                  <v-icon color="primary">mdi-open-in-new</v-icon>
                </v-btn>
              </template>
              <span>{{ $i18n.translate("Go to") }} {{ $i18n.translate("Participant") }}</span>
            </v-tooltip>
          </v-toolbar>
          <v-card-text>
            <v-text-field :label="$i18n.translate('Participant Key')" v-model="participant.participantKey" disabled>
            </v-text-field>
            <v-text-field :label="$i18n.translate('Name')" v-model="participant.fullName" disabled> </v-text-field>
            <v-text-field
              :label="$i18n.translate('Address')"
              :value="participant.address ? participant.address.fullAddress : ''"
              disabled
            >
            </v-text-field>
            <v-text-field
              :label="$i18n.translate('Participant Type')"
              v-model="participant.participantType.nameAndKey"
              disabled
            >
            </v-text-field>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card height="100%">
          <v-toolbar flat>
            <v-toolbar-title>{{ $i18n.translate("Organization") }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-tooltip bottom v-if="isGappInternalUser && organization.id">
              <template v-slot:activator="{ on: on }">
                <v-btn icon v-on="on" @click="$portal.navigateOrganization(organization.id)">
                  <v-icon color="primary">mdi-open-in-new</v-icon>
                </v-btn>
              </template>
              <span>{{ $i18n.translate("Go to") }} {{ $i18n.translate("Organization") }}</span>
            </v-tooltip>
          </v-toolbar>
          <v-card-text>
            <v-text-field
              :label="$i18n.translate('Organization Key')"
              v-if="organization"
              v-model="organization.organizationKey"
              disabled
            >
            </v-text-field>
            <v-text-field
              :label="$i18n.translate('Organization Name')"
              v-if="organization"
              v-model="organization.name"
              disabled
            ></v-text-field>
            <v-text-field
              :label="$i18n.translate('Organization DBA')"
              v-if="organization"
              v-model="organization.dba"
              disabled
            ></v-text-field>

            <v-text-field
              :label="$i18n.translate('Address')"
              v-if="organization"
              :value="organization.address ? organization.address.fullAddress : ''"
              disabled
            >
            </v-text-field>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CountryField from "../../../gapp-components/components/fields/CountryField.vue";
import PromotionTypeField from "../../../gapp-components/components/fields/PromotionTypeField.vue";
import DateTimePickerField from "../../../gapp-components/components/fields/DateTimePickerField.vue";
import ClaimStageField from "../../../gapp-components/components/fields/ClaimStageField.vue";
import ClaimTypeField from "../../../gapp-components/components/fields/ClaimTypeField.vue";
import { mapGetters } from "vuex";

export default {
  components: { ClaimTypeField, ClaimStageField, DateTimePickerField, PromotionTypeField, CountryField },
  name: "ClaimOverviewTab",
  data: () => ({
    loading: false,
    claimStage: {},
    promotion: {},
    promotionType: {},
    organization: {},
    claimType: {},
    participant: {
      participantType: {
        nameAndKey: ""
      }
    }
  }),
  props: {
    byPage: Boolean,
    claim: Object
  },
  computed: {
    ...mapGetters(["selectedClient"]),
    isGappInternalUser() {
      return this.$privilege.isGappInternalUser();
    },
    hasReferenceNumbers() {
      return this.claimProducts && this.claimProducts.existing
        ? this.claimProducts.existing.some(cp => cp.claimProductPayouts.some(cpp => cpp.referenceNumber))
        : false;
    },
    uploadedUserFullName() {
      if (this.uploadedUser && this.uploadedUser.fullName) {
        return this.uploadedUser.fullName;
      }
      return "";
    }
  },
  methods: {
    async fetchData() {
      this.loading = true;
      try {
        let claimStage = await this.$api.getRelatedObject("claimStage", this.claim);
        this.claimStage = claimStage.data;

        let participant = await this.$api.getRelatedObject("participant", this.claim);
        this.participant = participant.data;
        this.participant.participantType = {};
        let participantType = await this.$api.getRelatedObject("participantType", participant.data);
        this.participant.participantType = participantType.data;

        this.$set(
          this.participant.participantType,
          "nameAndKey",
          this.participant.participantType.name + " (" + this.participant.participantType.participantTypeKey + ")"
        );

        let promotion = await this.$api.getRelatedObject("promotion", this.claim);
        this.promotion = promotion.data;

        let claimType = await this.$api.getRelatedObject("claimType", promotion.data);
        this.claimType = claimType.data;

        let promotionType = await this.$api.getRelatedObject("promotionType", promotion.data);
        this.promotionType = promotionType.data;

        let organization = await this.$api.getRelatedObject("organization", participant.data);
        this.organization = organization.data;
      } catch (error) {
        this.loading = false;
      }

      this.loading = false;
    }
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    claim: {
      immediate: true,
      deep: true,
      handler() {
        this.fetchData();
      }
    }
  }
};
</script>
