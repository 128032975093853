var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.allowCRUDEditing)?_c('v-row',{attrs:{"justify":"end"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"fab":"","small":"","disabled":_vm.isSaving},on:{"click":_vm.toggleEditing}},on),[(_vm.isEditing)?_c('v-icon',[_vm._v("mdi-close")]):_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,false,3148299448)},[_c('span',[_vm._v(_vm._s(_vm.isEditing ? "Cancel editing" : "Edit Payouts"))])]),(_vm.isEditing)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"fab":"","small":"","disabled":_vm.isSaving},on:{"click":_vm.onSubmit}},on),[_c('v-icon',[_vm._v("mdi-content-save")])],1)]}}],null,false,4200968621)},[_c('span',[_vm._v("Save changes")])]):_vm._e()],1):_vm._e(),_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"width":"100%"},attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.payoutAmount",fn:function(ref){
var item = ref.item;
return [(_vm.isEditing)?_c('v-text-field',{attrs:{"type":"number","label":"Payout Amount","single-line":"","dense":""},on:{"input":function($event){item.dirty = true}},model:{value:(item.payoutAmount),callback:function ($$v) {_vm.$set(item, "payoutAmount", $$v)},expression:"item.payoutAmount"}}):_c('span',[_vm._v(_vm._s(_vm._f("toCurrency")(item.payoutAmount)))])]}},{key:"item.payoutType",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.payoutType.description))])]}},{key:"item.currency",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.currency.name))])]}},{key:"item.awardVehicleDetermination",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.awardVehicleDetermination.description))])]}},{key:"item.awardVehicleName",fn:function(ref){
var item = ref.item;
return [(_vm.isEditing)?_c('AwardVehicleField',{on:{"input":function($event){item.dirty = 'true'}},model:{value:(item.awardVehicle),callback:function ($$v) {_vm.$set(item, "awardVehicle", $$v)},expression:"item.awardVehicle"}}):_c('span',[_vm._v(_vm._s(item.awardVehicleName ? item.awardVehicleName : "No Award Vehicle Selected"))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.isEditing)?_c('v-icon',{on:{"click":function($event){item.markedForDeletion = true}}},[_vm._v(" mdi-delete ")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }