var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading)?_c('div',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{attrs:{"loading":_vm.loading,"height":"100%"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$i18n.translate("Claim")))]),_c('v-spacer'),(_vm.hasReferenceNumbers)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({staticClass:"mr-1",attrs:{"color":"orange"}},on),[_vm._v(_vm._s(_vm.$i18n.translate("Reference Numbers")))])]}}],null,false,2998885649)},[_c('span',[_vm._v(_vm._s(_vm.$i18n.translate("Includes Credit Memo Reference Numbers"))+" ")])]):_vm._e(),(_vm.claim.approved)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({attrs:{"color":"green"}},on),[_vm._v(_vm._s(_vm.$i18n.translate("Approved")))])]}}],null,false,3542569577)},[_c('span',[_vm._v(_vm._s(_vm.$i18n.translate("Approved"))+" "+_vm._s(_vm.claim.approvedUser && _vm.claim.approvedUser.fullName ? " " + _vm.$i18n.translate("by") + " " + _vm.claim.approvedUser.fullName : "")+" on "+_vm._s(_vm._f("formatDateClient")(_vm.claim.approvedDate,"MM/DD/YYYY hh:mm a z", _vm.selectedClient))+" ")])]):_vm._e(),(_vm.claim.rejected)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({attrs:{"color":"red"}},on),[_vm._v(_vm._s(_vm.$i18n.translate("Rejected")))])]}}],null,false,1802803044)},[_c('span',[_vm._v(_vm._s(_vm.$i18n.translate("Rejected"))+" "+_vm._s(_vm.claim.rejectedUser && _vm.claim.rejectedUser.fullName ? " " + _vm.$i18n.translate("by") + " " + _vm.claim.rejectedUser.fullName : "")+" on "+_vm._s(_vm._f("formatDateClient")(_vm.claim.rejectedDate,"MM/DD/YYYY hh:mm a z", _vm.selectedClient))+" ")])]):_vm._e(),(_vm.isGappInternalUser && _vm.claim.id)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.$portal.navigateClaim(_vm.claim.id)}}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-open-in-new")])],1)]}}],null,false,3956721207)},[_c('span',[_vm._v(_vm._s(_vm.$i18n.translate("Go to"))+" "+_vm._s(_vm.$i18n.translate("Claim")))])]):_vm._e()],1),_c('v-card-text',[_c('v-text-field',{attrs:{"label":_vm.$i18n.translate('Claim Key'),"disabled":""},model:{value:(_vm.claim.claimKey),callback:function ($$v) {_vm.$set(_vm.claim, "claimKey", $$v)},expression:"claim.claimKey"}}),_c('ClaimTypeField',{attrs:{"label":_vm.$i18n.translate('Claim Type'),"disabled":""},model:{value:(_vm.claimType),callback:function ($$v) {_vm.claimType=$$v},expression:"claimType"}}),_c('ClaimStageField',{attrs:{"claimType":_vm.claimType,"label":_vm.$i18n.translate('Claim Stage'),"disabled":""},model:{value:(_vm.claimStage),callback:function ($$v) {_vm.claimStage=$$v},expression:"claimStage"}}),_c('v-text-field',{attrs:{"label":_vm.$i18n.translate('Claim Amount'),"value":_vm.claim.calculatedCalculatedTotalClaimAmount + ' ' + _vm.claim.calculatedCalculatedTotalClaimAmountCurrency,"disabled":""}}),_c('v-text-field',{attrs:{"label":_vm.$i18n.translate('Paid Claim Amount'),"value":_vm.claim.calculatedCalculatedTotalClaimAmountPaid +
                ' ' +
                _vm.claim.calculatedCalculatedTotalClaimAmountPaidCurrency,"disabled":""}}),_c('v-text-field',{attrs:{"label":_vm.$i18n.translate('Approved') + '?',"value":_vm.claim.approved
                ? 'Yes' +
                  (_vm.claim.approvedUser && _vm.claim.approvedUser.fullName ? ', by ' + _vm.claim.approvedUser.fullName : '')
                : 'No',"disabled":""}}),_c('v-text-field',{attrs:{"label":_vm.$i18n.translate('Rejected') + '?',"value":_vm.claim.rejected
                ? 'Yes' +
                  (_vm.claim.rejectedUser && _vm.claim.rejectedUser.fullName ? ', by ' + _vm.claim.rejectedUser.fullName : '') +
                  ', because ' +
                  _vm.claim.rejectedReason
                : 'No',"disabled":""}}),_c('v-text-field',{attrs:{"loading":_vm.loading,"label":_vm.$i18n.translate('Follow Up'),"append-icon":"mdi-close","append-outer-icon":_vm.claim.followUp ? 'mdi-content-save' : ''},model:{value:(_vm.claim.followUp),callback:function ($$v) {_vm.$set(_vm.claim, "followUp", $$v)},expression:"claim.followUp"}}),_c('v-text-field',{attrs:{"label":"Who Uploaded","value":_vm.uploadedUserFullName,"disabled":""}}),_c('DateTimePickerField',{attrs:{"label":"Date Uploaded","disabled":""},model:{value:(_vm.claim.uploadedDate),callback:function ($$v) {_vm.$set(_vm.claim, "uploadedDate", $$v)},expression:"claim.uploadedDate"}}),_c('v-text-field',{attrs:{"label":_vm.$i18n.translate('Claim Browser Source'),"disabled":""},model:{value:(_vm.claim.browserSource),callback:function ($$v) {_vm.$set(_vm.claim, "browserSource", $$v)},expression:"claim.browserSource"}}),_c('v-text-field',{attrs:{"label":_vm.$i18n.translate('Claim Browser Referer'),"disabled":""},model:{value:(_vm.claim.browserReferer),callback:function ($$v) {_vm.$set(_vm.claim, "browserReferer", $$v)},expression:"claim.browserReferer"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{attrs:{"height":"100%"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$i18n.translate("Promotion")))]),_c('v-spacer'),(_vm.isGappInternalUser && _vm.promotion.id)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.$portal.navigatePromotion(_vm.promotion.id)}}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-open-in-new")])],1)]}}],null,false,2164378103)},[_c('span',[_vm._v(_vm._s(_vm.$i18n.translate("Go to"))+" "+_vm._s(_vm.$i18n.translate("Promotion")))])]):_vm._e()],1),_c('v-card-text',[_c('v-text-field',{attrs:{"label":_vm.$i18n.translate('Promotion Key'),"disabled":""},model:{value:(_vm.promotion.promotionKey),callback:function ($$v) {_vm.$set(_vm.promotion, "promotionKey", $$v)},expression:"promotion.promotionKey"}}),(_vm.promotion)?_c('v-text-field',{attrs:{"label":_vm.$i18n.translate('Promotion Name'),"disabled":""},model:{value:(_vm.promotion.name),callback:function ($$v) {_vm.$set(_vm.promotion, "name", $$v)},expression:"promotion.name"}}):_vm._e(),_c('PromotionTypeField',{attrs:{"label":_vm.$i18n.translate('Promotion Type'),"disabled":""},model:{value:(_vm.promotionType),callback:function ($$v) {_vm.promotionType=$$v},expression:"promotionType"}}),_c('CountryField',{attrs:{"label":_vm.$i18n.translate('Promotion') + ' ' + _vm.$i18n.translate('Country'),"disabled":"","available":_vm.promotion.country ? [_vm.promotion.country] : null},model:{value:(_vm.promotion.country),callback:function ($$v) {_vm.$set(_vm.promotion, "country", $$v)},expression:"promotion.country"}}),_c('br'),(_vm.promotionType.publiclyAvailable)?_c('v-chip',{staticClass:"primary",attrs:{"small":""}},[_vm._v(_vm._s(_vm.$i18n.translate("Publicly Available")))]):_vm._e()],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{attrs:{"height":"100%"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$i18n.translate("Participant")))]),_c('v-spacer'),(_vm.isGappInternalUser && _vm.participant.id)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.$portal.navigateParticipant(_vm.participant.id)}}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-open-in-new")])],1)]}}],null,false,3612561783)},[_c('span',[_vm._v(_vm._s(_vm.$i18n.translate("Go to"))+" "+_vm._s(_vm.$i18n.translate("Participant")))])]):_vm._e()],1),_c('v-card-text',[_c('v-text-field',{attrs:{"label":_vm.$i18n.translate('Participant Key'),"disabled":""},model:{value:(_vm.participant.participantKey),callback:function ($$v) {_vm.$set(_vm.participant, "participantKey", $$v)},expression:"participant.participantKey"}}),_c('v-text-field',{attrs:{"label":_vm.$i18n.translate('Name'),"disabled":""},model:{value:(_vm.participant.fullName),callback:function ($$v) {_vm.$set(_vm.participant, "fullName", $$v)},expression:"participant.fullName"}}),_c('v-text-field',{attrs:{"label":_vm.$i18n.translate('Address'),"value":_vm.participant.address ? _vm.participant.address.fullAddress : '',"disabled":""}}),_c('v-text-field',{attrs:{"label":_vm.$i18n.translate('Participant Type'),"disabled":""},model:{value:(_vm.participant.participantType.nameAndKey),callback:function ($$v) {_vm.$set(_vm.participant.participantType, "nameAndKey", $$v)},expression:"participant.participantType.nameAndKey"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{attrs:{"height":"100%"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$i18n.translate("Organization")))]),_c('v-spacer'),(_vm.isGappInternalUser && _vm.organization.id)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.$portal.navigateOrganization(_vm.organization.id)}}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-open-in-new")])],1)]}}],null,false,3965944407)},[_c('span',[_vm._v(_vm._s(_vm.$i18n.translate("Go to"))+" "+_vm._s(_vm.$i18n.translate("Organization")))])]):_vm._e()],1),_c('v-card-text',[(_vm.organization)?_c('v-text-field',{attrs:{"label":_vm.$i18n.translate('Organization Key'),"disabled":""},model:{value:(_vm.organization.organizationKey),callback:function ($$v) {_vm.$set(_vm.organization, "organizationKey", $$v)},expression:"organization.organizationKey"}}):_vm._e(),(_vm.organization)?_c('v-text-field',{attrs:{"label":_vm.$i18n.translate('Organization Name'),"disabled":""},model:{value:(_vm.organization.name),callback:function ($$v) {_vm.$set(_vm.organization, "name", $$v)},expression:"organization.name"}}):_vm._e(),(_vm.organization)?_c('v-text-field',{attrs:{"label":_vm.$i18n.translate('Organization DBA'),"disabled":""},model:{value:(_vm.organization.dba),callback:function ($$v) {_vm.$set(_vm.organization, "dba", $$v)},expression:"organization.dba"}}):_vm._e(),(_vm.organization)?_c('v-text-field',{attrs:{"label":_vm.$i18n.translate('Address'),"value":_vm.organization.address ? _vm.organization.address.fullAddress : '',"disabled":""}}):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }