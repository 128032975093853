var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.total,"options":_vm.options,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Claim Notes")]),_c('v-spacer'),_c('v-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('v-text-field',{staticClass:"mr-4",attrs:{"label":_vm.$i18n.translate('Filter') + ' ' + _vm.$i18n.translate('Notes'),"flat":"","solo-inverted":"","hide-details":"","clearable":"","clear-icon":"mdi-close-circle-outline"},on:{"click:clear":_vm.onClear},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(!_vm.disabled)?_c('v-dialog',{attrs:{"width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onDialog = ref.on;
var attrsDialog = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
var attrsTooltip = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":""}},'v-btn',Object.assign({}, attrsDialog, attrsTooltip),false),Object.assign({}, onDialog, onTooltip)),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,true)},[_c('span',[_vm._v("New Note")])])]}}],null,false,1748490725),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-form',{ref:"claimNoteForm",on:{"submit":function($event){$event.preventDefault();return _vm.onSave.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('ApiError',{attrs:{"errors":_vm.errors}}),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{staticClass:"required",attrs:{"name":"note","label":"Note","id":"note","rules":_vm.rules.note,"error-messages":_vm.$error.getValidationError(_vm.errors, 'note')},on:{"input":function($event){return _vm.$error.clearValidationError(_vm.errors, 'note')}},model:{value:(_vm.editedItem.note),callback:function ($$v) {_vm.$set(_vm.editedItem, "note", $$v)},expression:"editedItem.note"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","loading":_vm.loading},on:{"click":_vm.onClose}},[_vm._v(_vm._s(_vm.$i18n.translate("Cancel")))]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","loading":_vm.loading},on:{"click":_vm.onSave}},[_vm._v(_vm._s(_vm.$i18n.translate("Save")))])],1)],1)],1)],1):_vm._e()],1)]},proxy:true},{key:"item.createdDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateClient")(item.createdDate,"MM/DD/YYYY hh:mm a z", _vm.selectedClient))+" ("+_vm._s(_vm._f("formatDateFromNow")(item.createdDate))+") ")]}},{key:"item.note",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"word-wrap":"break-all"}},[_vm._v(" "+_vm._s(item.note)+" ")])]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.fullName)+" ")]}},(!_vm.disabled)?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.onDelete(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-delete")])]}}],null,true)},[_c('span',[_vm._v("Delete Note")])])]}}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }